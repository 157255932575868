import ConsentInput from "@/components/inputs/ConsentInput.vue";
import store from "@/store";
import colors from "@/styles/_colors.sass";
import {
  AddressAutocompleteInput,
  BasicButton,
  BlueprintBuilder,
  CheckBox,
  CheckboxGroup,
  ConfigurationWrapper,
  CountryInput,
  DynamicComponent,
  Event,
  FrankieIcon,
  GenericInput,
  GoAheadButton,
  ICloneable,
  IconRadioInput,
  LabelWrapper,
  NotificationBanner,
  Nullable,
  OrganisationPayload,
  ProgressBar,
  SelectOption,
  StateInput,
  // AddressAutocompleteInput,
  TextAreaInput,
} from "@frankieone/shared";

import { AxiosError } from "axios";
import debounce from "debounce";
import jwtDecode from "jwt-decode";
import format from "string-format";
import Vue, { VueConstructor } from "vue";
import Fragment from "vue-fragment";
import { COMPONENTS } from "../store/modules/system/routes";
import { initialisePreload } from "@/plugins/config/preload";
import { initialiseEnableDeviceCharacteristics } from "@/plugins/config/enableDeviceCharacteristics";

format.extend(String.prototype, {});

export enum WIDTH_SIZES {
  DESKTOP = "ff-desktop",
  TABLET = "ff-tablet",
  MOBILE = "ff-mobile",
}

type ObservedSizes = {
  size: WIDTH_SIZES;
  height: Nullable<number>;
  width: Nullable<number>;
  screenHeight: Nullable<number>;
  screenWidth: Nullable<number>;
};
const OBSERVED_SIZE_WRAPPER = Vue.observable({
  size: WIDTH_SIZES.MOBILE,
  height: null,
  width: null,
  screenHeight: null,
  screenWidth: null,
} as ObservedSizes);
const onSizeChange = debounce(
  (width: number, height: number) => {
    OBSERVED_SIZE_WRAPPER.height = height;
    OBSERVED_SIZE_WRAPPER.width = width;
    if (width <= 600) {
      OBSERVED_SIZE_WRAPPER.size = WIDTH_SIZES.MOBILE;
    } else if (width <= 1024) {
      OBSERVED_SIZE_WRAPPER.size = WIDTH_SIZES.TABLET;
    } else {
      OBSERVED_SIZE_WRAPPER.size = WIDTH_SIZES.DESKTOP;
    }
  },
  200,
  false
);
const onWindowSizeChange = debounce(
  () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    OBSERVED_SIZE_WRAPPER.screenHeight = height;
    OBSERVED_SIZE_WRAPPER.screenWidth = width;
  },
  200,
  false
);

export function initialiseComponents(VueInstance: VueConstructor) {
  VueInstance.use(Fragment.Plugin);
  VueInstance.component("DynamicComponent", DynamicComponent);
  VueInstance.component("BlueprintBuilder", BlueprintBuilder);
  VueInstance.component("FrankieIcon", FrankieIcon);
  VueInstance.component("LabelWrapper", LabelWrapper);
  VueInstance.component("GenericInput", GenericInput);
  VueInstance.component("GoAheadButton", GoAheadButton);
  VueInstance.component("BasicButton", BasicButton);
  VueInstance.component("ProgressBar", ProgressBar);
  VueInstance.component("CheckBox", CheckBox);
  VueInstance.component("SelectOption", SelectOption);
  VueInstance.component("CheckBoxGroup", CheckboxGroup);
  VueInstance.component("StateInput", StateInput);
  VueInstance.component("CountryInput", CountryInput);
  VueInstance.component("NotificationBanner", NotificationBanner);
  VueInstance.component("AddressAutocompleteInput", AddressAutocompleteInput);
  VueInstance.component("IconRadioInput", IconRadioInput);
  VueInstance.component("TextAreaInput", TextAreaInput);
  VueInstance.component("ConsentInput", ConsentInput);
}
interface BufferSnapshotAndAllFilled<T> {
  buffer: T;
  snapshot: string;
  value: ICloneable<any>;
  emitIsAllFilled: () => void;
  isAllFilled: boolean;
}
export const CreationMixin = {
  created<T>(this: Vue & BufferSnapshotAndAllFilled<T>) {
    if (typeof this.snapshot !== "undefined" && this.value) {
      this.snapshot = String(Math.random());
    }
    if (typeof this.buffer !== "undefined" && this.value?.clone) {
      this.buffer = this.value.clone();
    }
    if (typeof this.emitIsAllFilled !== "undefined") {
      this.emitIsAllFilled();
    } else if (typeof this.isAllFilled !== "undefined") {
      this.$emit("isAllFilled", this.isAllFilled);
    }
  },
};
export const beforeCreated = {
  install(VueInstance: VueConstructor) {
    initialiseComponents(VueInstance);
    Vue.config.ignoredElements = ["ff-onfido-widget"];

    VueInstance.prototype.$OBSERVED_SIZE_WRAPPER = OBSERVED_SIZE_WRAPPER;

    VueInstance.mixin({
      computed: {
        environmentMode: () => store.getters.environmentMode,
        isDemo: () => store.getters.isDemo,
        isDevelopment: () => store.getters.isDevelopment,
        isProduction: () => store.getters.isProduction,
        $log: () => store.getters.logger,
        IDS: () => COMPONENTS,
        COLORS: () => colors,
        $phrases: () => store.getters.config("phrases"),
        $feature: () => store.getters.feature,
      },
      methods: {
        getPhrase: store.getters.phrase,
        async dispatchEvent(options: { eventName: eventNames; payload?: Event["data"] }) {
          await store.dispatch.dispatchEvent({
            eventName: options.eventName,
            payload: options.payload,
          });
        },
      },
    });

    VueInstance.mixin(CreationMixin);

    VueInstance.mixin({
      computed: {
        minHeightPx: () => "400px",
        minWidthPx: () => "400px",
        isDesktop(): boolean {
          return this.responsiveClass === this.RESPONSIVE_CLASSES.DESKTOP;
        },
        isMobile(): boolean {
          return this.responsiveClass === this.RESPONSIVE_CLASSES.MOBILE;
        },
        isTablet(): boolean {
          return this.responsiveClass === this.RESPONSIVE_CLASSES.TABLET;
        },
        responsiveClass(): WIDTH_SIZES {
          return OBSERVED_SIZE_WRAPPER.size;
        },
        widthPx(): Nullable<number> {
          return OBSERVED_SIZE_WRAPPER.width;
        },
        heightPx(): Nullable<number> {
          return OBSERVED_SIZE_WRAPPER.height;
        },
        screenWidthPx(): Nullable<number> {
          return OBSERVED_SIZE_WRAPPER.screenWidth;
        },
        screenHeightPx(): Nullable<number> {
          return OBSERVED_SIZE_WRAPPER.screenHeight;
        },
        RESPONSIVE_CLASSES(): Dictionary<string> {
          return WIDTH_SIZES;
        },
      } as { [k: string]: any } & ThisType<{
        responsiveClass: WIDTH_SIZES;
        RESPONSIVE_CLASSES: typeof WIDTH_SIZES;
      }>,
      mounted(this: Vue) {
        if (this.$parent) return;
        (this.$el as HTMLElement).style?.setProperty("--minHeight", this.minHeightPx);
        (this.$el as HTMLElement).style?.setProperty("--minWidth", this.minWidthPx);
        onWindowSizeChange();
        window.addEventListener("resize", onWindowSizeChange);
        const updateSize = (width?: number, height?: number) => {
          const theHeight = height ?? this.$el.clientHeight;
          const theWidth = width ?? this.$el.clientWidth;
          onSizeChange(theWidth, theHeight);
        };
        updateSize();
        const fallbackObserver = () => window.addEventListener("resize", () => updateSize());
        if (window.ResizeObserver) {
          try {
            new window.ResizeObserver((entries) => {
              const entry = entries?.[0];
              if (entry) {
                const target = entry.contentRect;
                const { width, height } = target;
                updateSize(width, height);
              }
            }).observe(this.$el);
          } catch (e) {
            fallbackObserver();
          }
        } else {
          fallbackObserver();
        }
      },
    });
  },
};

// type Sizing = {
//   widthParameter: string;
//   heightParameter: string;
//   widthApplied: string;
//   heightApplied: string;
//   widthClass: string;
//   heightClass: string;
// }
// export type InitialiseConfigurationOptions = {
//   config: DeepPartial<IWidgetConfiguration>;
//   applicantReference: Nullable<string>;
//   ff: Nullable<string>;
//   sizing: Sizing;
// }

export const afterCreated = {
  install(
    _: any,
    options: {
      config: any;
      applicantReference: any;
      ff: string;
      onComplete: () => void;
      onError: (e: Error) => void;
    }
  ) {
    try {
      initialiseConfiguration(options).then(options.onComplete).catch(options.onError);
    } catch (e) {
      options.onError(e as any);
    }
  },
};
type Options = { config: DeepPartial<IWidgetConfiguration>; ff: string } & ({ applicantReference: string } | { entityId: string });
export async function initialiseConfiguration(options: Options) {
  const { config, ff } = options;

  const { applicantReference } = options as { applicantReference: string };
  const { entityId } = options as { entityId: string };

  const charset = document.characterSet;
  if (charset !== "UTF-8") console.warn("For proper visualisation of FrankieOne's Smart UI, please set meta charset to 'UTF-8'. For more info, visit https://www.w3schools.com/html/html_charset.asp");
  await store.dispatch.resetData();
  try {
    const configuration = await store.dispatch.initializeConfiguration(config);

    const hasDisabledAnalytics = configuration.get("disableThirdPartyAnalytics").v();
    const env = configuration.get("mode").v();
    const { env: environment, log } = setEnvironment(env);

    log.info("development", "environment variables", process.env);
    log.info("development", "final configuration", configuration.v());
    log.info("development", entityId ? "entity id" : "applicant reference", entityId ?? applicantReference);
    log.info("development", "ffToken", ff);

    type PayloadData = {
      organisation: OrganisationPayload;
      referrer: string | null;
      permissions: string[];
      sessionId: string;
    };
    let payloadData: PayloadData | null = null;
    if (ff && process.env.NODE_ENV !== "test") {
      await store.dispatch.setToken(ff!);
      payloadData = jwtDecode<{ data: PayloadData }>(ff).data;
    }

    initialisePreload(configuration);

    await initialiseEnableDeviceCharacteristics(configuration, ff);

    log.info("development", "loaded organisation", store.state.session.organisation);

    type ParsedDocumentUpload = Exclude<IWidgetConfiguration["documentUploads"], boolean> | false;
    const documentUploads: ParsedDocumentUpload = configuration.get("documentUploads").v();
    const uploadsConfig = !documentUploads ? [] : documentUploads.uploads;

    await store.dispatch.initialiseSupportingDocumentsList(uploadsConfig.length);

    if ((applicantReference || entityId) && !environment.isDemo()) {
      const reference = entityId ? { entityId } : applicantReference;
      await store.dispatch.setApplicantReference(reference);
    }
    // initialize applicant profile after prefill
    await store.dispatch.initializeApplicantProfile();
    if (!environment.isProduction()) (window as any).ffOnboardingWidgetStore = store;
    await store.dispatch.initializeViews();
    await store.dispatch.initialiseAddresses();
    store.dispatch.initialiseDOBType();
    const sessionPayload: any = payloadData || { sessionPayload: "no-session" };
    await store.dispatch.initializeEventsFactory({
      sessionId: payloadData?.sessionId ?? "no-session",
      customerId: payloadData?.organisation.customerId ?? "no-session",
    });
    const { phrases, ...mainConfig } = store.getters.config();

    const loggedConfig = {
      ...mainConfig,
      idScanVerification: {
        ...mainConfig.idScanVerification,
        welcomeScreen: "REDACTED",
        injectedCss: "REDACTED"
      },
      consentText: "REDACTED"
    }

    await store.dispatch.dispatchEvent({
      eventName: "INIT",
      payload: {
        ...sessionPayload,
        applicantReference,
        configuration: loggedConfig,
      },
    });
  } catch (e) {
    console.error(e);

    throw mkInitialiserError(e);
  }
}
const codeTypes = {
  401: "unauthorized",
  404: "not_found",
};
const mkInitialiserError = (e): TWigetError => {
  const isAxiosError = (e as AxiosError)?.isAxiosError;
  if (isAxiosError) {
    const status = (e as AxiosError).response?.status;
    const message = (e as AxiosError).response?.data?.message;
    const type = codeTypes[String(status)] ?? "other";
    return {
      message,
      type,
    };
  }
  return {
    message: (e as Error).message,
    type: "configuration",
  };
};

export function setEnvironment(environment: string) {
  store.state.system.environment = environment;
  return { env: store.getters.env, log: store.getters.logger };
}

// use this function to enable 3rd party analytics
// currently commented out but planning on adding a new analytics service in the near future. Please don't remove unless discussed with the team

// function enableThirdPartyAnalytics() {

// }
